
import request from '@/utils/request';



class DealApi {
    

    list = (data) => {

        return request({
            url: '/adm/deal/list',
            method: 'post',
            data
        });	

    }

    detail = (data) => {

        return request({
            url: '/adm/deal/basic/deal_detail',
            method: 'post',
            data
        });	
    }

   


    type_lsit = () => {
        return request({
            url: '/adm/config/deal/deal_type',
            method: 'post',
        });	
    }



   


    delete_folder =(data) => {
        return request({
            url: '/adm/deal/deal_delete_folder',
            method: 'post',
            data
        });	
    }

   

    delete_file =(data) => {
        return request({
            url: '/adm/deal/file/deal_delete_file',
            method: 'post',
            data
        });	
    }







    add_property= (data) => {
        return request({
            url: '/adm/deal/deal_add_property',
            method: 'post',
            data
        });	
    }

    add_file =(data) => {
        return request({
            url: '/adm/deal/file/deal_add_file',
            method: 'post',
            data
        });	
    }

    update_file_name =(data) => {
        return request({
            url: '/adm/deal/file/file_update_name',
            method: 'post',
            data
        });	
    }

    add_folder =(data) => {
        return request({
            url: '/adm/deal/deal_add_folder',
            method: 'post',
            data
        });	
    }

    add_extra_fee =(data) => {
        return request({
            url: '/adm/deal/commission/charge_agent_fee',
            method: 'post',
            data
        });	
    }

    post_deal_message = (data) => {
        return request({
            url: '/adm/deal/file/post_deal_message',
            method: 'post',
            data
        });	
    }

    remove_charged_fee =(data) => {
        return request({
            url: '/adm/deal/commission/remove_charged_fee',
            method: 'post',
            data
        });	
    }

    edit_agent_commission = (data) => {
        return request({
            url: '/adm/deal/commission/edit_agent_commission',
            method: 'post',
            data
        });	
    }




   

    deal_process = (data) => {
        return request({
            url: '/adm/deal/start_to_proces',
            method: 'post',
            data
        });	
    }
    deal_reject = (data) => {
        return request({
            url: '/adm/deal/reject_deal',
            method: 'post',
            data
        });	
    }

    deal_approve = (data) => {
        return request({
            url: '/adm/deal/approve_deal',
            method: 'post',
            data
        });	
    }





    mark_folder_status = (data) => {
        return request({
            url: '/adm/deal/deal_folder_mark',
            method: 'post',
            data
        });	
    }
    

    generate_cir_report = (data) => {
        return request({
            url: '/adm/deal/formfile/deal_regenerate_cir_report',
            method: 'post',
            data
        });	
    }
    

    generate_mls_report = (data) => {
        return request({
            url: '/adm/deal/formfile/deal_generate_mls_sales_report',
            method: 'post',
            data
        });	
    }
    



    send_sales_report =(data) => {
        return request({
            url: '/adm/deal/deal_send_sales_report',
            method: 'post',
            data
        });	
    }


    add_agent_referral = (data) => {
        return request({
            url: '/adm/deal/commission/add_agent_referral',
            method: 'post',
            data
        });	
    
    }

    delete_agent_referral = (data) => {
        return request({
            url: '/adm/deal/commission/remove_agent_referral',
            method: 'post',
            data
        });	
    

        
    }
    edit_referral_comm = (data) => {
        return request({
            url: '/adm/deal/commission/edit_referral_comm',
            method: 'post',
            data
        });	
    }

    zip_files =(data) => {
        return request({
            url: '/adm/deal/file/deal_zip_download_files',
            method: 'post',
            data
        });	
    } 

   
    send_files = (data) => {
        return request({
            url: '/adm/deal/deal_send_files',
            method: 'post',
            data
        });	
    }
    

}




let _api = null

const getDealApi = () => {
    if (!_api) {
        _api = new DealApi();
    }
    return _api;
}

export { getDealApi };


